@import url("https://use.typekit.net/ojg8osh.css");


body,
html,
.dx-widget {
    font-family: 'bebas-neue-pro-semiexpanded' !important;
    font-size: 16px;

}

.dx-widget input {
    font-family: 'bebas-neue-pro-semiexpanded';
}

.dx-swatch-additional .dx-widget {
    font-size: 16px;
}

.dx-datagrid .dx-row>td {
    font-size: 16px;
}

.dx-texteditor-input {
    font-size: 18px;
}

.text-smaller .dx-texteditor-input,
div.text-smaller {
    font-size: smaller;
    font-family: source-code-pro, monospace;
    font-weight: 400;
    font-style: normal;
}

.dx-textarea .dx-texteditor-input,
.text-smaller {
    line-height: 1.2;

}

.text-bigger {
    font-size: 20px;
    font-family: 'bebas-neue-pro-semiexpanded';
}

.small-text {
    font-size: 10px;
    opacity: 0.7;
}


footer {
    font-family: isonorm !important;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;


}

.login-image {
    max-height: 60px;
    max-width: 100px;
    margin: auto;
}

//override bootstrap padding
.container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: none;
}

.dx-datagrid-summary-item,
.dx-datagrid-text-content {
    overflow: visible;
}

.dx-field-item-content:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs) {
    padding-top: 0px;
}

.dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-filled .dx-texteditor-input,
.dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-outlined .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-filled .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-outlined .dx-texteditor-input {
    padding-right: 5px;
}